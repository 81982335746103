export const cognitoConfig = (env) => {
  return {
    Auth: {
      Cognito: {
        userPoolId: env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolClientId: env.REACT_APP_COGNITO_CLIENT_ID,
        loginWith: {
          oauth: {
            domain: env.REACT_APP_COGNITO_OAUTH_DOMAIN,
            scope: ['openid', 'email', 'profile' , 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${env.REACT_APP_FRONTHOST}/callback`,
            redirectSignOut: `${env.REACT_APP_FRONTHOST}/`,
            responseType: 'code'
          }
        }
      }
    }
  }
}
