export const vocabularies = {
  ja: {
    'Redirecting ...': 'リダイレクト中・・・',
    'is mandatory': 'は必須入力です。',
    'Applied Technology co.,LTD.': '応用技術株式会社',
    'Sign In': 'サインイン',
    'Sign Up': 'サインアップ',
    'Sign Out': 'サインアウト',
    'Sign in to your account': 'アカウントにサインイン',
    'Username *': 'ユーザー名 *',
    'Password *': 'パスワード *',
    'Enter your username': 'ユーザー名を入力',
    'Enter your password': 'パスワードを入力',
    'No account?': 'アカウントが未登録ですか？',
    'Forgot your password?': 'パスワードをお忘れですか？',
    'Reset password': 'パスワードをリセット',
    'User does not exist': 'ユーザーが存在しません',
    'User already exists': 'ユーザーは既に存在します',
    'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
    'Invalid password format': 'パスワードのフォーマットが不正です',
    'Create account': 'アカウントを作成',
    'Forgot Password': 'パスワードを忘れた',
    'Change Password': 'パスワードを変更',
    'New Password': '新しいパスワード',
    'Email': 'Email',
    'Phone Number': '電話番号',
    'Confirm a Code': 'コードを確認',
    'Confirm Sign In': 'サインインを確認',
    'Confirm Sign up': 'サインアップを確認',
    'Back to Sign In': 'サインインに戻る',
    'Send Code': 'コードを送信',
    'Confirm': '確認',
    'Resend Code': 'コードを再送',
    'Submit': '送信',
    'Skip': 'スキップ',
    'Verify': '検証',
    'Verify Contact': '連絡先を検証',
    'Code': 'Code',
    'Confirmation Code': '確認コード',
    'Lost your code?': 'コードがありませんか？',
    'Account recovery requires verified contact information': 'Account recovery requires verified contact information',
    'Invalid phone number format': '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
    'Create Account': 'アカウントを作成',
    'Have an account?': 'アカウントをお持ちですか？',
    'Sign in': 'サインイン',
    'Create a new account': '新しいアカウントを作成',
    'Reset your password': 'パスワードをリセット',
    'An account with the given email already exists.': 'そのメールアドレスは既に存在します',
    'Username cannot be empty': 'ユーザー名は必須です',
    'Password attempts exceeded': 'パスワード試行回数が超過しました',
    "Email is required": 'Emailは必須です',
    "Password must have at least 8 characters": 'パスワードは8文字以上で入力してください',
    "Password must contain at least 1 number": "パスワードには少なくとも1つの数字を含める必要があります",
    "Password must contain at least 1 special character": "パスワードには少なくとも1つの記号を含める必要があります",
    "Password must contain at least 1 lowercase letter": "パスワードには少なくとも1つの小文字を含める必要があります",
    "Password must contain at least 1 uppercase letter": "パスワードには少なくとも1つの大文字を含める必要があります",
    "Password is required": 'パスワードは必須です',
    "Password must not contain any spaces": "パスワードにはスペースを含めることはできません",
    "Confirm Password is required": 'パスワードの確認は必須です',
    "Your passwords must match": 'パスワードが一致しません。',
    "Family Name": '姓',
    "Enter your Family Name" : '姓を入力',
    "Family Name is required": '会社名は必須です',
    "Given Name": '名',
    "Enter your Given Name" : '名を入力',
    "Given Name is required": '会社名は必須です',
    "Company": '会社名',
    "Enter your Company" : '会社名を入力',
    "Company is required": '会社名は必須です',
    "Department": '所属（役職）',
    "Enter your Department" : '所属（役職）を入力',
    "Department is required": '所属（役職）は必須です',
    "Address": '住所',
    "Enter your Address" : '住所を入力',
    "Tel": '電話番号',
    "Enter your Tel" : '電話番号を入力',
    "The personal information entered will be handled in accordance with Applied Technology Co., Ltd.'s privacy policy.":"入力された個人情報等は応用技術株式会社のプライバシーポリシーに則り取り扱います。",
    "I agree":"同意する",
    "Privacy Policy":"個人情報保護方針について",
    "This field is required":"このフィールドは必須です",
    "At least 8 characters (including at least one uppercase letter, one lowercase letter, one number, and one symbol)":"8文字以上（大文字、小文字、数字、記号を各1文字以上含む)",
    "※If you do not agree to the handling of personal information, you will not be able to create an account.":"※個人情報の取り扱いについて同意いただけない場合は、アカウントは作成できません。",
    "Invalid email format":"不正なメールアドレスフォーマットです",
    "※To create an account, you must review and accept the Terms of Use.":"※アカウントを作成するには、「利用規約」を確認し、同意する必要があります。"
  }
}
