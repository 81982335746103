import "./App.css";
import React, { useEffect,useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import {
  Authenticator,
  translations,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { cognitoConfig } from "./AwsConfig";
import { Hub, I18n } from "aws-amplify/utils";

import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { vocabularies } from "./Vocabularies";
import queryString from "query-string";

Amplify.configure(cognitoConfig(process.env));

const { lang = "ja" } = queryString.parse(document.location.search);
I18n.putVocabularies(translations);
I18n.putVocabularies(vocabularies);
I18n.setLanguage(lang);

function App({ user }) {
  const formFields = {
    signUp: {
      email: {
        label: (
          <>
            <span style={{ color: "red" }}>*</span>
            {I18n.get("Email")}
          </>
        ),
        isRequired: true,
        order: 1,
      },
      password: {
        label: (
          <>
            <span style={{ color: "red" }}>*</span>
            {I18n.get("Password")}
          </>
        ),
        isRequired: true,
        order: 2,
      },
      confirm_password: {
        label: (
          <>
            <span style={{ color: "red" }}>*</span>
            {I18n.get("Confirm Password")}
          </>
        ),
        isRequired: true,
        order: 3,
      },
      family_name: {
        label: (
          <>
            <span style={{ color: "red" }}>*</span>
            {I18n.get("Family Name")}
          </>
        ),
        isRequired: true,
        order: 4,
      },
      given_name: {
        label: (
          <>
            <span style={{ color: "red" }}>*</span>
            {I18n.get("Given Name")}
          </>
        ),
        isRequired: true,
        order: 5,
      },
      "custom:company": {
        isRequired: true,
        label: (
          <>
            <span style={{ color: "red" }}>*</span>
            {I18n.get("Company")}
          </>
        ),
        placeholder: I18n.get("Enter your Company"),
        // errorMessage: I18n.get('Company is required'),
        order: 6,
      },
      "custom:post": {
        isRequired: true,
        label: (
          <>
            <span style={{ color: "red" }}>*</span>
            {I18n.get("Department")}
          </>
        ),
        placeholder: I18n.get("Enter your Department"),
        order: 7,
      },
      "custom:address": {
        isRequired: false,
        label: I18n.get("Address"),
        placeholder: I18n.get("Enter your Address"),
        order: 8,
      },
      "custom:tel": {
        isRequired: false,
        label: I18n.get("Tel"),
        placeholder: I18n.get("Enter your Tel"),
        order: 9,
      },
    },
  };

  useEffect(() => {
    (async () => {
      await signOut();
      Hub.listen("auth", async (data) => {
        if ("signedIn" === data.payload.event) {
          async function currentSession() {
            try {
              const fetchedSession = await fetchAuthSession();
              const tokens = fetchedSession.tokens;
              const tokenKey = Object.keys(localStorage).filter((k) =>
                k.endsWith("refreshToken")
              )[0];
              const refreshToken = localStorage.getItem(tokenKey);
              const { accessToken, idToken } = tokens;
              const at = accessToken.toString();
              const it = idToken.toString();
              document.location.href = `${process.env.REACT_APP_COGNITO_REGIRECT_SIGN_IN}/#id_token=${it}&access_token=${at}&refresh_token=${refreshToken}&expires_in=3600&token_type=Bearer`;
            } catch (err) {
              console.log(err);
            }
          }
          await currentSession();
        }
      });
    })();
  }, []);

  return (
    <>
      <div
        style={{ margin: "50px 0 30px 0", width: "100%", textAlign: "center" }}
      >
        <img alt={"まちすぺ"} src={"/logo.jpg"} />
      </div>
      <Authenticator
        formFields={formFields}
        loginMechanisms={["email"]}
        signUpAttributes={["email", "family_name", "given_name", "address"]}
        components={{
          SignUp: {
            Header() {
              return (
                <>
                  <div style={{ marginLeft: "32px" }}>
                    <span style={{ color: "red" }}>*</span>
                    {I18n.get("is mandatory")}
                  </div>
                </>
              );
            },
            Footer() {
              return <div></div>;
            },
            FormFields() {
              const { validationErrors } = useAuthenticator();
              const [isLinkClicked, setIsLinkClicked] = useState(false);

              const handleLinkClick = () => {
                setIsLinkClicked(true);
              };
              return (
                <>
                  {/* Re-use default `Authenticator.SignUp.FormFields` */}
                  <Authenticator.SignUp.FormFields />
                  {/* Append & require Terms and Conditions field to sign up */}
                  <div
                    style={{
                      padding: "10px",
                      fontFamily: "Arial, sans-serif",
                      maxHeight: "200px",
                      overflowY: "auto",
                      border: "1px solid #ccc", // Optional: adds a border for clarity
                      borderRadius: "5px", // Optional: adds rounded corners
                    }}
                  >
                    {lang === "en" ? (
                      <div>
                        <h3>Regarding the Handling of Personal Information</h3>
                        <p>
                          The personal information entered during account
                          creation will be managed based on our company's
                          Personal Information Protection Management System.
                        </p>
                        <ul>
                          <li>
                            Personal information will be used for account
                            creation procedures, as well as for information
                            about our products/services, seminars, and responses
                            to inquiries.
                          </li>
                          <li>
                            The person responsible for managing personal
                            information is the President and CEO.
                          </li>
                          <li>
                            We will not provide personal information to third
                            parties.
                          </li>
                          <li>
                            Personal information may be outsourced for
                            processing to partner companies with whom we have
                            signed confidentiality agreements.
                          </li>
                        </ul>
                        <p>
                          If you would like to request disclosure, etc. of the
                          personal information provided to us, please contact
                          our "Personal Information Protection Policy"
                          inquiry/customer service desk at the following link:
                          <a
                            href="https://www.apptec.co.jp/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            Personal Information Protection Policy
                          </a>
                          .
                        </p>
                        <h4>
                          Please check the box if you agree to the above
                          content.
                        </h4>
                      </div>
                    ) : lang === "ja" ? (
                      <div>
                        <h3>個人情報の取り扱いについて</h3>
                        <p>
                          アカウント作成時に記載された個人情報は、弊社の個人情報保護マネジメントシステムに基づき管理させていただきます。
                        </p>
                        <ul>
                          <li>
                            個人情報は、本サービスのアカウント作成手続きのほか、当社の製品/サービスおよびセミナー等のご案内、お問い合わせへの対応に使用します。
                          </li>
                          <li>個人情報管理責任者は、代表取締役社長です。</li>
                          <li>第三者への提供は行いません。</li>
                          <li>
                            個人情報は、機密保持契約を締結している協力会社に処理を委託する場合があります。
                          </li>
                        </ul>
                        <p>
                          弊社に提供した個人情報に関して、開示等を御求めの場合は
                          <a
                            href="https://www.apptec.co.jp/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            「個人情報保護方針について」
                          </a>
                          に記載の個人情報保護に関するお問合せ・お客様相談窓口にご連絡下さい。
                        </p>
                        <h4>
                          上記の内容に同意いただける場合はチェックを入れてください。
                        </h4>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <input type="checkbox" id="consent-checkbox" />
                    <label htmlFor="consent-checkbox">
                      {I18n.get("I agree")}
                    </label>
                  </div>
                  {validationErrors.consent && (
                    <div style={{ color: "red" }}>
                      {validationErrors.consent}
                    </div>
                  )}
                  <div
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      fontFamily: "Arial, sans-serif",
                      maxHeight: "200px",
                      // overflowY: "auto",
                      border: "1px solid #ccc", // Optional: adds a border for clarity
                      borderRadius: "5px", // Optional: adds rounded corners
                    }}
                  >
                    {lang === "en" ? (
                      <div>
                        <h3>Terms of Use</h3>
                        <p>
                        Before applying for Mspace. services, please be sure to read  Mspace. 「
                          <a
                            href="https://mspace.apptec.co.jp/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                            onClick={handleLinkClick}
                          >
                            Terms of Use
                          </a>
                          」
                        </p>
                        <h4>
                          Please check the box if you agree to the terms of use.
                        </h4>
                      </div>
                    ) : lang === "ja" ? (
                      <div>
                        <h3>利用規約について</h3>
                        <p>
                        まちスペースのサービスにお申込みいただく前に、まちスペースの「
                          <a
                            href="https://mspace.apptec.co.jp/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                            onClick={handleLinkClick}
                          >
                            利用規約
                          </a>
                          」を必ずご確認ください
                        </p>
                        <h4>
                        「利用規約」の内容に同意頂ける場合はチェックをいれてください。
                        </h4>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <input type="checkbox" id="terms-checkbox"  disabled={!isLinkClicked} />
                    <label htmlFor="terms-checkbox">
                      {I18n.get("I agree")}
                    </label>
                  </div>
                  {validationErrors.terms && (
                    <div style={{ color: "red" }}>
                      {validationErrors.terms}
                    </div>
                  )}

                  {/* Append & require Terms and Conditions field to sign up  */}
                </>
              );
            },
          },
        }}
        services={{
          async validateCustomSignUp(formData) {
            const requiredFields = [
              "email",
              "password",
              "confirm_password",
              "family_name",
              "given_name",
              "custom:company",
              "custom:post",
            ];
            const errors = {};
            if (formData.email && formData.email.length > 0) {
              const emailRegex =
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

              if (!emailRegex.test(formData.email)) {
                return { email: I18n.get("Invalid email format") };
              }
            }
            if (formData.password && formData.password.length > 0) {
              const passwordChecks = [
                {
                  regex: /^\S*$/,
                  message: "Password must not contain any spaces",
                },
                {
                  regex: /[0-9]/,
                  message: "Password must contain at least 1 number",
                },
                {
                  regex: /[!@#$%^&*(),.?":{}|<>]/,
                  message: "Password must contain at least 1 special character",
                },
                {
                  regex: /[A-Z]/,
                  message: "Password must contain at least 1 uppercase letter",
                },
                {
                  regex: /[a-z]/,
                  message: "Password must contain at least 1 lowercase letter",
                },
              ];

              for (const check of passwordChecks) {
                if (!check.regex.test(formData.password)) {
                  return { password: I18n.get(check.message) };
                }
              }
            }
            if (
              formData.confirm_password &&
              formData.confirm_password.length > 0
            ) {
              if (formData.password !== formData.confirm_password) {
                return {
                  confirm_password: I18n.get("Your passwords must match"),
                };
              }
            }
            for (const field of requiredFields) {
              if (!formData[field]) {
                if (field === "password") {
                  return {
                    [field]: I18n.get(
                      "At least 8 characters (including at least one uppercase letter, one lowercase letter, one number, and one symbol)"
                    ),
                  };
                }
                return { [field]: I18n.get("This field is required") };
              }
            }

            const consentCheckbox = document.getElementById("consent-checkbox");
            if (!consentCheckbox || !consentCheckbox.checked) {
              return {
                consent: I18n.get(
                  "※If you do not agree to the handling of personal information, you will not be able to create an account."
                ),
              };
            }
            const termsCheckbox = document.getElementById("terms-checkbox");
            if (!termsCheckbox || !termsCheckbox.checked) {
              return {
                terms: I18n.get(
                  "※To create an account, you must review and accept the Terms of Use."
                ),
              };
            }

            return errors;
          },
        }}
      >
        {() => (
          <main>
            <h1>{I18n.get("Redirecting ...")}</h1>
          </main>
        )}
      </Authenticator>
      <div
        style={{ margin: "50px 0 30px 0", width: "100%", textAlign: "center" }}
      >
        <div>
          <a href={"https://www.apptec.co.jp/"}>
            <img
              style={{ width: "250px" }}
              alt={"応用技術株式会社"}
              src={"/companyLogo.png"}
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default App;
